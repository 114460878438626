

:root {
  --black: #09090c;
  --grey: #a4b2bc;
  --white: #fff;
  --background: #ebedef;
}


*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: var(--background);
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #004e73;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #004e73;
}