.sign-in-bg {
    background: linear-gradient(0deg, rgb(255,255,0) 0%, rgba(14, 42, 98, 1) 86%);
    display: grid;
    height: 100vh;
}

.sign-in-alignment {
    height: 100vh;
    display: grid;
    align-items: center;
    align-content: center;
}

.sign-in-text {
    font-weight: 600;
    font-size: 30px;
    color: rgba(0, 0, 0, 0.8);
}
.applogiq-admin-heading{
    font-weight: 600;
        font-size: 50px;
        color: #000000;
        padding-bottom:50px;
}
.credentail-text {
    font-weight: 400;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.5);
}

.label-text {
    font-weight: 500;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
}

.custom-overflow {
    overflow-x: hidden;
}

.font-errors {
    font-size: small;
    height: 10px !important;
}

.commit-alignment {
    width: 20%;
}
input{
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    background-clip: padding-box;
    color: #768192;
    background-color: #fff;
    border: 1px solid #d8dbe0;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    cursor: pointer;
  }

  .login-applogiq{
    width: 70%;
    margin: 0 auto;
    box-shadow: 0px 3px 12px rgb(0 0 0 / 30%);
    padding: 30px;
  }
